<div *transloco="let t; read: 'actionBar'" class="flex flex-row gap-2">
    <div *ngIf="dropdownMode; then dropdown; else normal"></div>
    <ng-template #dropdown>
        <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            [matTooltip]="t('more')"
            aria-label="Example icon-button with a menu"
            saveButton
            [showWarn]="showWarn()">
            <mat-icon svgIcon="more_horiz"></mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <div class="w-full" *ngFor="let action of actionDescriptors()">
                <!-- <button mat-menu-item>
                    {{ action.title }}
                </button> -->
                <button
                    *ngIf="!action.hide"
                    [id]="action.code"
                    HasRoleAndPermission
                    [hasPermissionsAndRoles]="action.hasPermissionsAndRoles"
                    mat-menu-item
                    class="{{ action.class }}"
                    [color]="action.color"
                    (click)="doAction(action)"
                    saveButton
                    [automaticDisable]="action.automaticDisableForSave"
                    [showWarn]="action.showWarn"
                    mode="bgColour">
                    <mat-icon [svgIcon]="action.icon"></mat-icon>
                    {{ action.tooltip }}
                </button>
            </div>
            <ng-content select="[menuContent]"></ng-content>
        </mat-menu>
    </ng-template>
    <ng-template #normal>
        <div class="w-fit" *ngFor="let action of actionDescriptors()">
            <button
                *ngIf="action.style === buttonType().simple && !action.hide"
                [id]="action.code"
                HasRoleAndPermission
                [hasPermissionsAndRoles]="action.hasPermissionsAndRoles"
                mat-button
                [ngClass]="action.class"
                [color]="action.color"
                (click)="doAction(action)"
                [matTooltip]="action.tooltip"
                saveButton
                [automaticDisable]="action.automaticDisableForSave"
                [showWarn]="action.showWarn">
                <mat-icon *ngIf="action.iconPosition === 'pre'" [svgIcon]="action.icon"></mat-icon>
                {{ action.title }}
                <mat-icon *ngIf="action.iconPosition === 'post'" [svgIcon]="action.icon"></mat-icon>
            </button>
            <button
                *ngIf="action.style === buttonType().raised && !action.hide"
                [id]="action.code"
                HasRoleAndPermission
                [hasPermissionsAndRoles]="action.hasPermissionsAndRoles"
                mat-raised-button
                [ngClass]="action.class"
                [color]="action.color"
                (click)="doAction(action)"
                [matTooltip]="action.tooltip"
                saveButton
                [automaticDisable]="action.automaticDisableForSave"
                [showWarn]="action.showWarn">
                <mat-icon *ngIf="action.iconPosition === 'pre'" [svgIcon]="action.icon"></mat-icon>
                {{ action.title }}
                <mat-icon *ngIf="action.iconPosition === 'post'" [svgIcon]="action.icon"></mat-icon>
            </button>
            <button
                *ngIf="action.style === buttonType().flat && !action.hide"
                [id]="action.code"
                HasRoleAndPermission
                [hasPermissionsAndRoles]="action.hasPermissionsAndRoles"
                mat-flat-button
                [ngClass]="action.class"
                [color]="action.color"
                (click)="doAction(action)"
                [matTooltip]="action.tooltip"
                saveButton
                [automaticDisable]="action.automaticDisableForSave"
                [showWarn]="action.showWarn">
                <mat-icon *ngIf="action.iconPosition === 'pre'" [svgIcon]="action.icon"></mat-icon>
                {{ action.title }}
                <mat-icon *ngIf="action.iconPosition === 'post'" [svgIcon]="action.icon"></mat-icon>
            </button>
            <button
                *ngIf="action.style === buttonType().stroked && !action.hide"
                [id]="action.code"
                HasRoleAndPermission
                [hasPermissionsAndRoles]="action.hasPermissionsAndRoles"
                mat-stroked-button
                [ngClass]="action.class"
                [color]="action.color"
                (click)="doAction(action)"
                [matTooltip]="action.tooltip"
                saveButton
                [automaticDisable]="action.automaticDisableForSave"
                [showWarn]="action.showWarn">
                <mat-icon *ngIf="action.iconPosition === 'pre'" [svgIcon]="action.icon"></mat-icon>
                {{ action.title }}
                <mat-icon *ngIf="action.iconPosition === 'post'" [svgIcon]="action.icon"></mat-icon>
            </button>
            <button
                *ngIf="action.style === buttonType().fab && !action.hide"
                [id]="action.code"
                HasRoleAndPermission
                [hasPermissionsAndRoles]="action.hasPermissionsAndRoles"
                mat-fab
                [ngClass]="action.class"
                [color]="action.color"
                (click)="doAction(action)"
                [matTooltip]="action.tooltip"
                saveButton
                [automaticDisable]="action.automaticDisableForSave"
                [showWarn]="action.showWarn">
                <mat-icon *ngIf="action.iconPosition === 'pre'" [svgIcon]="action.icon"></mat-icon>
                {{ action.title }}
                <mat-icon *ngIf="action.iconPosition === 'post'" [svgIcon]="action.icon"></mat-icon>
            </button>
            <button
                *ngIf="action.style === buttonType().miniFab && !action.hide"
                [id]="action.code"
                HasRoleAndPermission
                [hasPermissionsAndRoles]="action.hasPermissionsAndRoles"
                mat-mini-fab
                [ngClass]="action.class"
                [color]="action.color"
                (click)="doAction(action)"
                [matTooltip]="action.tooltip"
                saveButton
                [automaticDisable]="action.automaticDisableForSave"
                [showWarn]="action.showWarn">
                <mat-icon *ngIf="action.iconPosition === 'pre'" [svgIcon]="action.icon"></mat-icon>
                {{ action.title }}
                <mat-icon *ngIf="action.iconPosition === 'post'" [svgIcon]="action.icon"></mat-icon>
            </button>
            <button
                *ngIf="action.style === buttonType().icon && !action.hide"
                [id]="action.code"
                HasRoleAndPermission
                [hasPermissionsAndRoles]="action.hasPermissionsAndRoles"
                mat-icon-button
                [ngClass]="action.class"
                [color]="action.color"
                (click)="doAction(action)"
                [matTooltip]="action.tooltip"
                saveButton
                [automaticDisable]="action.automaticDisableForSave"
                [showWarn]="action.showWarn">
                <mat-icon [svgIcon]="action.icon"></mat-icon>
            </button>
        </div>
        <ng-content></ng-content>
    </ng-template>
</div>
